export function guessThStyle(label, index) {
  let ret = {
    'max-width': '300px'
  }
  if (label.includes('序号')||label.includes('行次')) {
    ret = {width: '70px', 'text-align': 'center'}
  } else if (label.includes('日期')) {
    ret = {width: '100px'}
  } else if (label.includes('时间')) {
    ret = {width: '100px'}
  } else if (label.includes('地区')) {
    ret = {width: '100px'}
  } else if (label.includes('信息类型')) {
    ret = {width: '100px'}
  } else if (label.includes('变更类型')) {
    ret = {width: '250px'}
  }else if (label.includes('资产（单位：万元）')||label.includes('负债（单位：万元）')) {
    ret = {width: '450px'}
  }else if (label.includes('最近2年连续供应年限')) {
    ret = {width: '100px','white-space':'wrap'}
  }
  return ret
}

export function guessTdStyle(value, index,label="") {
  if (value == null) {
    return {};
  }
  if(typeof value =='string'){
    value = value.replace(/%$/,'')
  }

  let ret = {}
  value = value.toString().replace(/%$/,'')
  // value = value.replace(/万/,'')
  // value = value.replace(/人民币/,'')
  // value = value.replace(/元/,'')
  // value = value.replace(/升/,'')
  // value = value.replace(/月/,'')
  // value = value.replace(/年/,'')
  if (index === 0) {
    ret = {
      'text-align': 'center'
    }
  } else if (label=='状态') {
    ret = {'text-align':'center'}
  }else if (label.includes('日期')) {
    ret = {'text-align':'center'}
  } else if (label.includes('时间')) {
    ret = {'text-align':'center'}
  } else if (value == '-') {
    ret = {
      'text-align': 'center'
    }
  } else if (!isNaN(value)) {
    ret = {
      'text-align': 'right'
    }
  } else {
    ret = {
      'text-align': 'justify'
    }
  }
  return ret
}

/**
 *把 html的table代码片段html文件的代码
 * @param tableHtml
 * @param tableName
 */
export function convertHtmlTableFragmentToHtmlFile(tableHtml,tableName){
  const template = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <meta charset="UTF-8">
    <title>{tableName}</title>
    <style>
        table {
            border-collapse: separate;
            border-spacing: 0;
            margin: 0 auto;
            border-width: 0;
            table-layout: fixed;
            width: 100%;
            outline-width: 0;
            cursor: default;
            max-width: none;
            max-height: none;
            border-left: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
            line-height: 1.5;
        }

        table tr th {
            background: #f7f7f7;
            color: #333;
            font-weight: normal;
            padding: 5px;
            font-size: 14px;
            border-top: 1px solid #dcdcdc;
            border-right: 1px solid #dcdcdc;
            text-align: left;
            line-height: 1.5;
        }


        tr td {
            background: #f7f7f7;
            border: 0 none;
            padding: 5px;
            font-size: 14px;
            line-height: 1.5;
            border-right: 1px solid #dcdcdc;
            color: #999;
            border-top: 1px solid #dcdcdc;
        }
    </style>
</head>
<body>
{tableHtml}
</table>
</body>
</html>
`

  return template.replace('{tableName}',tableName).replace('{tableHtml}',tableHtml)
}
